<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt="" />
    </b-link>
      <div style="font-size:30px; font-weight: bold; color: #FFF;">Currently in maintenance</div>
      <div style="color: #FFF;">Will be back shortly</div>
    <a class="mercatus-logo" href="https://cooling.mercatus.pt/pt/home" target="https://cooling.mercatus.pt/pt/home">
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="" />
    </a>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton } from 'bootstrap-vue'
import CostLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    CostLogo,
    BLink,
    BButton,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import "@core/scss/vue/pages/page-auth.scss";
</style>
